.holder { }

/*
.holderA { background-color: #1abc9c; text-align: center; margin: 0 auto;}
*/
.socket { background-color: #444; width: 20vw; color: #fff; border: 5px solid #000; }

/*
.socket-left { border-left: 5px solid #000; border-top: 5px solid #000;border-bottom: 5px solid #000;}
.socket-center { border-top: 5px solid #000;border-bottom: 5px solid #000;}
.socket-right { border-right: 5px solid #000; border-top: 5px solid #000;border-bottom: 5px solid #000;}
*/
.pin-1 { font-size: 2vw; border: 2px solid #000; width: 1vw; border-radius: 4px; }
.pin-2 { font-size: 1.8vw; border: 2px solid #000; width: 1vw; border-radius: 4px;}
.pin-3 { font-size: 1vw; border: 2px solid #000; width: 1vw; border-radius: 4px;}
.pin-4 { font-size: 1vw; border: 2px solid #000; width: 1vw; border-radius: 4px; height: 1vw; }

.socket ul {list-style: none; font-size: 36px; font-weight: bold; padding: 0;}
.socket ul.center-small {  }
.socket ul.big-side li  {     margin: 0 1.5vw; }

.socket li div {
    margin: 0.5vw 0;
    cursor: pointer;
}


.socket li[data-value="0"] div { background-color: #222; }
.socket li[data-value="1"] div { background-color: #ccc;  box-shadow: 5px 5px 6px -2px black;   }
.socket li div.pinNum { z-index: 1; font-size: 1vw; top: -2.5vw; left: -1.5vw; position: relative; background-color: transparent !important; box-shadow: none !important;}

.socket div.border-center { height: 100%; width: 10px; background-color: #000;  }
.socket div {text-align: center;}
