@font-face {
    font-family: 'VWHead-Bold';
    src: url('../fonts/VWHead-Bold.otf') format('otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'VWHead-Regular';
    src: url('../fonts/VWHead-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VW Text';
    src: url('../fonts/VWText-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VW Text Bold';
    src: url('../fonts/VWText-Bold.otf') format('otf');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'VW Text', sans-serif;
}

